import styled from 'styled-components';
import { Button, Link } from '@rea-group/construct-kit-core';

export const ResponsiveButton = styled(Button).attrs({ fullWidth: true })`
    ${({ theme }) => theme.breakpoints.medium} {
        width: auto;
    }
`;

export const ResponsiveLinkButton = styled(Link).attrs({ variant: 'button-outline', fullWidth: true })`
    ${({ theme }) => theme.breakpoints.medium} {
        width: fit-content;
    }
`;
